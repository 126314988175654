import { protectedResources } from '../../../msalAuthConfig';
import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState, useContext } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';
import SharedForm from '../../../components/ui/Form';
import { USER_ROLE, ALIGN, POSITIONS, ALERT_TYPE, COMPANY_STATUS_VALUE, USER_ROLE_VALUES } from '../../../constants';
import { getUsers } from '../../../ducks/user';
import { useDispatch, useSelector } from 'react-redux';
import { acquireAccessToken } from '../../../services/api/auth';
import addInterceptors from '../../../utils/interceptors';
import createInstance from '../../../utils/instance';
import { isUserSysAdmin, isObjectEmpty, isValidName, optionValues } from '../../../utils/utils';
import { getCompany } from '../../../ducks/company';
import withAuth from '../../../components/hoc/withAuth';
import messages from '../../../utils/helper/messages';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import { AlertContext } from "../../../context/AlertContext";

export const ApiInstance = addInterceptors(createInstance());

const AddUserModal = ({ show, onCancel }) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const currentUser = useSelector((state) => state.user.currentUser);
    const companies = useSelector((state) => state.company.companies);

    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const roleOptions = optionValues(USER_ROLE_VALUES, true);
    const [companyOptions, setCompanyOptions] = useState(null);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            if (currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN) {
                try {
                    dispatch(getCompany());

                } catch (error) {
                    showAlert(`${messages.error.prefix} Error fetching company details.`, ALERT_TYPE.ERROR);
                }
            }
            else {
                setCompanyOptions([{ id: currentUser.companyId, value: currentUser.companyName }])
            }
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (!currentUser || (currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN && !companies)) {
            return;
        }
        if (companies && companies.length > 0 && currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN) {
            setCompanyOptions(companies.map((company) => ({ id: company.id, value: company.name })));
        }
    }, [companies]);

    useEffect(() => {
        if (currentUser && userDetails?.roleId == USER_ROLE.SYSTEM_ADMIN && currentUser.companyRecordStatus === COMPANY_STATUS_VALUE.Permanent) {
            const companyData = companies.filter((company) => company.id === currentUser.companyId);
            setCompanyOptions(companyData.map((company) => ({ id: company.id, value: company.name })));
            setUserDetails((prevState) => ({ ...prevState, companyId: companyData[0].id }));
        }
        else if (companies && companies.length > 0 && currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN) {
            setCompanyOptions(companies.map((company) => ({ id: company.id, value: company.name })));
        }

    }, [userDetails?.roleId]);

    const arr = [
        { key: 'firstName', labelName: 'First Name', placeholderName: 'First Name', type: 'text' },
        { key: 'lastName', labelName: 'Last Name', placeholderName: 'Last Name', type: 'text' },
        { key: 'email', labelName: 'Email', placeholderName: 'Email', type: 'email' },
        { key: 'phone', labelName: 'Phone Number', placeholderName: 'Phone Number', type: 'tel', maxLength: 10 },
        {
            key: 'roleId', labelName: 'User Role', type: 'options',
            optionValues: isUserSysAdmin(currentUser) && currentUser.companyRecordStatus === COMPANY_STATUS_VALUE.Permanent ? roleOptions : roleOptions?.filter((option) => Number(option.id) !== Number(USER_ROLE.SYSTEM_ADMIN))
        },
        { key: 'companyId', labelName: 'Company Name', type: 'options', optionValues: companyOptions },
    ];

    const isValidUserInfo = () => {
        if (isObjectEmpty(userDetails)) {
            return false;
        }

        return isValidName(userDetails.firstName);
    };

    const submitCallback = async (formData) => {
        setIsLoading(true);

        try {
            let accessToken = await acquireAccessToken(instance);
            ApiInstance.setAuth(accessToken);
            ApiInstance.setContext(showAlert);

            const user = await ApiInstance({
                method: 'POST',
                url: protectedResources.apiUsers.endpoint,
                data: {
                    email: formData.email,
                    roleId: formData.roleId,
                    companyId: formData.companyId,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    phone: formData.phone,
                },
            });

            if (user.data) {
                await ApiInstance({
                    method: 'POST',
                    url: protectedResources.apiUserInvite.endpoint,
                    data: {
                        inviteUserId: user.data.id,
                    },
                });
                showAlert(messages.success.createUser, ALERT_TYPE.SUCCESS);
                setUserDetails(null);
                cancelCallback();
                dispatch(getUsers(instance));
            }

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showAlert(`${messages.error.prefix} ${error?.response?.data?.error || error}.`, ALERT_TYPE.ERROR);
        }
    };

    const cancelCallback = () => {
        setUserDetails(null);
        onCancel();
    };

    const handleUserDetailsChange = (update) => {
        setUserDetails((details) => ({ ...details, ...update }));
    };

    const inputChange = (name, value) => {
        handleUserDetailsChange({ [name]: value });
    };

    return (
        <>
            <Modal
                show={show}
                fullscreen={false}
                backdrop="static"
                onHide={onCancel}
                size="md">
                <Modal.Header closeButton>
                    <Modal.Title>New User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="px-xl-3 py-xl-2">
                        {companyOptions && <SharedForm
                            cols={12}
                            array={arr}
                            modelObj={userDetails}
                            onSubmit={submitCallback}
                            onCancel={cancelCallback}
                            onInputChanged={inputChange}
                            loading={isLoading}
                            disabled={!isValidUserInfo()}
                            actionBarPosition={POSITIONS.TOP}
                            actionBarAlign={ALIGN.RIGHT}
                        />}
                    </div>
                    {userDetails && !isValidUserInfo() && (
                        <Alert
                            variant="warning"
                            className="mt-2 py-2 lh-sm">
                            <div className="d-flex align-items-center">
                                <BsFillExclamationTriangleFill className="me-2" />
                                {messages.error.userDetailsValidation}
                            </div>
                        </Alert>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default withAuth(AddUserModal);
