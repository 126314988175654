import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import { ALIGN, POSITIONS, ALERT_TYPE, VALIDATIONS, AG_MEETING_ATTENDANCE_LINK_TYPE } from "../../../../constants";
import { addComment, getCommentsByMeetingId, resetMeetingMemberComments } from '../../../../ducks/advisoryGroup';
import messages from '../../../../utils/helper/messages';
import { AlertContext } from '../../../../context/AlertContext';
import Comment from '../../../../../src/components/ui/Comment';
import { AppLoadContext } from "../../../../components/ui/AppLoadContext";
import Loader from "../../../../components/ui/Loader";

const NonMemberCommentsModal = ({ show, data, onComment, info, meeting }) => {

    const { showAlert } = useContext(AlertContext);
    const isAppLoaded = useContext(AppLoadContext);
    const dispatch = useDispatch();

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [commentDetails, setCommentDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    
    const comments = useSelector(state => state.advisoryGroup?.commentList);

    useEffect(() => {
        if (isAppLoaded && info.agNonMemberId) {
            dispatch(getCommentsByMeetingId({
                meetingId: meeting.id,
                agMemberId: info.agNonMemberId,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.NonMember
            }));
        }
    }, [isAppLoaded, info.agNonMemberId]);

    const arr = [
        {
            key: 'comment',
            labelName: showCommentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !showCommentBox,
            valid: VALIDATIONS.String.noSpecialChars,
        }
    ];

    const onHideCallback = () => {
        onComment();
        setShowCommentBox(false);
        dispatch(resetMeetingMemberComments());
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleSubmit = (formData) => {
        setIsLoading(true);

        if (formData.comment) {
            const commentData = {
                agId: meeting.agId,
                meetingId: meeting.id,
                companyId: info.companyId,
                agMemberId: info.agNonMemberId,
                userId: info.userId,
                comment: formData.comment,
                linkType: AG_MEETING_ATTENDANCE_LINK_TYPE.NonMember,
            };

            dispatch(addComment(commentData, () => {
                setIsLoading(false);
                showAlert(messages.success.addNonMemberComment, ALERT_TYPE.SUCCESS);
                setShowCommentBox(false);
                onComment();
                dispatch(resetMeetingMemberComments());
            }, handleError));
        }
    };

    const handleAddComment = () => setShowCommentBox(true);

    const handleCommentDetailsChange = (update) => setCommentDetails((details) => ({ ...details, ...update }));

    const inputChange = (name, value) => handleCommentDetailsChange({ [name]: value });

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={'Submit'}
                        loading={isLoading}
                        disabled={!showCommentBox || commentDetails?.comment === ''}
                        onInputChanged={inputChange}
                    />
                    {comments === null ? (
                        <Loader size={2} />
                    ) : (
                        <Comment
                            comments={comments}
                            showUserName={true}
                            onAddComment={handleAddComment}
                            commentBox={showCommentBox}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default NonMemberCommentsModal;
