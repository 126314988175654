import { Accordion } from 'react-bootstrap';
import styles from './HelpMenu.module.scss';

const FaqItem = ({ evKey, title, children }) => {
    return (
        <Accordion.Item eventKey={evKey} className="border-bottom border-bottom-1">
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body className="px-4">
                {children}
            </Accordion.Body>
        </Accordion.Item>
    )
}

const Faqs = () => {
    return (
        <div className="py-2">
            <Accordion
                className={`mx-n4 ${styles.faqs}`}
                flush>
                <FaqItem
                    evKey="0"
                    title="FAQ Question One">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer nec odio. Praesent libero. Sed cursus ante
                        dapibus diam. Sed nisi.
                    </p>
                </FaqItem>
                <FaqItem
                    evKey="1"
                    title="FAQ Question Two">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer nec odio. Praesent libero. Sed cursus ante
                        dapibus diam. Sed nisi.
                    </p>
                </FaqItem>
                <FaqItem
                    evKey="2"
                    title="FAQ Question Three">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer nec odio. Praesent libero. Sed cursus ante
                        dapibus diam. Sed nisi.
                    </p>
                </FaqItem>
                <FaqItem
                    evKey="3"
                    title="FAQ Question Four">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer nec odio. Praesent libero. Sed cursus ante
                        dapibus diam. Sed nisi.
                    </p>
                </FaqItem>
            </Accordion>
        </div>
    );
};

export default Faqs;
