import React from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { HELP_SCREENS } from '../../../constants';
import styles from './HelpMenu.module.scss';

const HelpMenuSearch = ({ screen }) => {
    const handleSearch = () => {
        // handleSearch
    };

    return (
        <div
            className={`bg-secondary flex-shrink-0 ${styles.helpSearchContainer} ${screen === HELP_SCREENS.faqs && styles.searchFaqs}`}>
            <div
                className={`search-box position-absolute start-50 translate-middle ${styles.helpSearch}`}>
                <InputGroup>
                    <Form.Control
                        placeholder={`Search ${screen === HELP_SCREENS.faqs ? 'FAQs' : 'Help'}`}
                        aria-label={`Search ${screen === HELP_SCREENS.faqs ? 'FAQs' : 'Help'}`}
                        aria-describedby="basic-search"
                    />
                    <Button
                        variant="light"
                        id="button-search"
                        onClick={handleSearch}>
                        <FaSearch size={16} />
                    </Button>
                </InputGroup>
            </div>
        </div>
    )
}

export default HelpMenuSearch;
