import styles from './HelpMenu.module.scss';

const UpdateItem = ({ label, value, children }) => {
    return (
        <div>
            <strong className="me-1">{label}:</strong>
            {value && value}
            {children?.length > 0 && (
                <div
                    className={styles.updateSummary}
                    dangerouslySetInnerHTML={{ __html: children }}
                />
            )}
        </div>
    );
};

const Update = ({ item, children }) => {
    const { title, application, lastUpdate } = item;

    return (
        <>
            <div className="vstack gap-3">
                <UpdateItem
                    label="Title"
                    value={title}
                />
                <UpdateItem
                    label="Application"
                    value={application}
                />
                <UpdateItem label="Summary">{children}</UpdateItem>
                <UpdateItem
                    label="Last Update"
                    value={lastUpdate}
                />
            </div>
            <div className="text-secondary my-3 py-3">
                <hr className="opacity-100 my-0" />
            </div>
        </>
    );
};

export default Update;
