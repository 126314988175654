import React from 'react';
import styles from './HelpMenu.module.scss';
import { Button } from 'react-bootstrap';
import { HELP_SCREENS } from '../../../constants';
import Icon from '../Icon';

const HelpNavItem = ({ icon, title, onClick }) => {
    return (
        <Button className={`w-100 bg-transparent rounded-0 d-flex align-items-center px-4 ${styles.navLink}`} onClick={onClick}>
            <Icon icon={icon} size={16} className="me-2" />
            {title}
        </Button>
    );
}

const Main = ({ onScreenChange }) => {
    return (
        <>
            <div className={`flex-column mx-n4 ${styles.nav}`}>
                <HelpNavItem icon="screen-help" title="Screen Help" onClick={() => onScreenChange(HELP_SCREENS.screenHelp)} />
                <HelpNavItem icon="frequently-asked-questions" title="Frequently Asked Questions" onClick={() => onScreenChange(HELP_SCREENS.faqs)} />
                <HelpNavItem icon="contact-support" title="Contact Support" onClick={() => onScreenChange(HELP_SCREENS.support)} />
                <HelpNavItem icon="whats-new" title="What's New" onClick={() => onScreenChange(HELP_SCREENS.whatsNew)} />
                <HelpNavItem icon="version-information" title="Version Information" onClick={() => onScreenChange(HELP_SCREENS.version)} />
            </div>
            <div className="intro py-4">
                <h4 className={`fw-bold ${styles.introTitle}`}>
                    Get Started with PHARO
                </h4>
                <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua. At vero eos et accusam et
                    justo duo dolores et ea rebum.
                </p>
                <p>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor
                    invidunt ut labore et dolore magna aliquyam erat, sed diam
                    voluptua. At vero eos et accusam et justo duo dolores et ea
                    rebum.
                </p>
            </div>
        </>
    );
};

export default Main;
