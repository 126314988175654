import React from "react";
import Modal from "react-bootstrap/Modal";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { FaExclamationTriangle } from "react-icons/fa";
import styles from "./ConfirmModal.module.scss";


const ConfirmModal = ({ show, message, variant = "danger", title = "Confirm", onConfirm, onCancel, onHideCallback, primaryBtnTitle = "Yes" }) => {
    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant={variant}>
                    <FaExclamationTriangle size={14} className={`${styles.icon} me-2`} />
                    {message}
                </Alert>
                <div className="mt-2 text-end">
                    {onCancel && <Button variant="light" className="me-2" onClick={onCancel}>Cancel</Button>}
                    <Button variant="primary" onClick={onConfirm}>{primaryBtnTitle}</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmModal;
