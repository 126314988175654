import React, { useContext, useEffect, useState } from 'react';
import { NavigationBar } from './NavigationBar';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useLocation, Outlet, useNavigate, useMatches } from "react-router-dom";
import SidebarNew from './SidebarNew';
import logo from "../../assets/images/logo-footer.png";
import styles from './Layout.module.scss';
import { useDispatch, useSelector } from "react-redux";
import HeaderBar from "./HeaderBar";
import { getInheritedRoles } from "../../utils/utils";
import { setLocation } from "../../ducks/navigation";
import BreadCrumbs from "./BreadCrumbs";
import { SubscriptionContext } from '../../context/SubscriptionContext';
import { SUBSCRIPTION } from '../../constants';
import HelpMenu from './help-menu/HelpMenu';


function Layout() {
    let matches = useMatches();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const year = new Date().getFullYear();
    const location = useLocation();
    const appSubscription = useContext(SubscriptionContext);
    const pageLocation = useSelector((state) => state.location.currentLocation);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [title, setTitle] = useState(pageLocation.page);
    const [accessCheck, setAccessCheck] = useState(false);
    const [subscriptionCheck, setSubscriptionCheck] = useState(false);
    const [helpMenu, setHelpMenu] = useState(false);

    const checkUnauthorized = (roles) => {
        if (currentUser && roles && !roles.includes(currentUser.roleId)) {
            navigate('unauthorized');
            dispatch(setLocation({ path: '/unauthorized', name: 'Home', page: 'Unauthorized' }));
        }
    }

    const checkIsSubscribed = () => {
        const subscriptionKey = matches
            .filter((match) => Boolean(match.handle?.subscription))
            .map(match => match.handle?.subscription)

        const checkMatchHasSubscription = Boolean(matches.filter((match) => match.handle?.subscription !== undefined).length);
        // check if section is an app and restrict access by app subscription
        if (checkMatchHasSubscription && !appSubscription?.includes(SUBSCRIPTION[subscriptionKey])) {
            navigate('unauthorized');
            dispatch(setLocation({ path: '/unauthorized', name: 'Home', page: 'Unauthorized' }));
        }
    }

    const handleHelpMenuToggle = () => {
        setHelpMenu(prevState => !prevState);
    }

    useEffect(() => {
        let roles = getInheritedRoles(matches);
        if (roles) {
            checkUnauthorized(roles);
        }
        setAccessCheck(true);
    }, [location.pathname]);

    useEffect(() => {
        if (appSubscription) {
            checkIsSubscribed();
            setSubscriptionCheck(true);
        }
    }, [location.pathname, appSubscription]);

    useEffect(() => {
        setTitle(pageLocation.page);
    }, [pageLocation.page]);

    return (
        <>
            <AuthenticatedTemplate>
                <main className={styles.contentWrap}>
                    <SidebarNew access={accessCheck} subscription={subscriptionCheck} />
                    {
                        (accessCheck && subscriptionCheck) ?
                            <div className={`flex-grow-1 d-flex flex-column position-relative ${styles.content}`}>
                                <NavigationBar onToggleHelpMenu={handleHelpMenuToggle} />
                                {title && <HeaderBar />}
                                <BreadCrumbs />
                                <div className={`flex-grow-1 d-flex flex-column ${styles.contentInner}`}>
                                    <Outlet />
                                </div>
                                <footer className={`bg-white px-3 ${styles.footer}`}>
                                    <div className={`text-center text-primary ${styles.footerText}`}>
                                        &copy; <strong>Predict Health, Inc</strong>{' '}
                                        {year}. All rights reserved.
                                    </div>
                                </footer>
                            </div>
                            :
                            null
                    }
                    <HelpMenu show={helpMenu} onClose={() => setHelpMenu(false)} />
                </main>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <NavigationBar />
                <main className="flex-grow-1">
                    <Outlet />
                </main>
                <footer
                    className={`px-3 px-xl-4 pt-4 pt-xl-5 pb-3 pb-xl-4 d-md-flex justify-content-md-between align-items-md-center`}>
                    <a
                        className="ps-xl-3"
                        href="https://predicthealth.ai"
                        target="_blank"
                        rel="noreferrer">
                        <img
                            src={logo}
                            alt="Predict Health"
                            className={styles.logo}
                        />
                    </a>
                    <div className={`text-center text-primary pe-xl-3 ${styles.footerText}`}>
                        &copy; <strong>Predict Health, Inc</strong> {year}. All
                        rights reserved.
                    </div>
                </footer>
            </UnauthenticatedTemplate>
        </>
    );
}

export default Layout;
