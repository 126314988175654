export const companyContactInfo = {
    phone: '123-456-7890',
    chat: {
        label: 'Chat hours',
        hours: 'M-F 6:00 am to 8:00 pm EST',
    },
    supportCenter: {
        label: 'Support Center hours',
        hours: 'M-F 8:00 am to 6:00 pm EST',
        phoneNumber: '(800) 123-4567'
    }
}

export const pharoVersionInfo = 'PH01243.2';
