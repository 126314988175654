import React from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import styles from './HelpMenu.module.scss';
import { HELP_SCREENS, HELP_SCREENS_TITLE } from '../../../constants';

const HelpMenuHeader = ({ screen, onClose, onBack }) => {

    const getScreenTitle = () => {
        switch (screen) {
            case HELP_SCREENS.main:
                return HELP_SCREENS_TITLE.main;
            case HELP_SCREENS.screenHelp:
                return HELP_SCREENS_TITLE.screenHelp;
            case HELP_SCREENS.faqs:
                return HELP_SCREENS_TITLE.faqs;
            case HELP_SCREENS.support:
                return HELP_SCREENS_TITLE.support;
            case HELP_SCREENS.whatsNew:
                return HELP_SCREENS_TITLE.whatsNew;
            case HELP_SCREENS.version:
                return HELP_SCREENS_TITLE.version;
            default:
                return null;
        }
    }

    return (
        <div className={`bg-secondary flex-shrink-0 ${styles.header}`}>
            <div className="d-flex align-items-center flex-grow-1">
                <Button variant="light" className="bg-transparent p-0 border-0 me-2" onClick={onBack}>
                    <FaQuestionCircle
                        size={20}
                        className="text-white"
                    />
                </Button>
                <div className="fs-5 text-white text-center flex-grow-1 text-truncate" style={{ width: 'calc(100% - 60px)' }}>
                    {getScreenTitle()}
                </div>
                <Button
                    className="btn-sm bg-transparent border-0 opacity-100 btn-close"
                    onClick={onClose}></Button>
            </div>
        </div>
    );
};

export default HelpMenuHeader;
