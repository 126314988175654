import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaImage } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import styles from './ClientLogo.module.scss';
import { getCompanyLogoById } from '../../ducks/user'

const ClientLogo = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        if (currentUser) {
            dispatch(getCompanyLogoById(currentUser.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, currentUser?.companyId]);

    return (
        <Link to="/" className={`${styles.clientLogo}`}>
            {currentUser ?
                <img src={`${currentUser.fileURL?.imageSrc}`}
                    alt={currentUser.companyName} height={50} width={'auto'} />
                :
                <div className="d-flex align-items-center">
                    <FaImage size={16} className="me-2" />Company Logo
                </div>
            }
        </Link>
    )
}

export default ClientLogo;
