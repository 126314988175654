import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import withAuth from '../../hoc/withAuth';
import { FaArrowLeft } from "react-icons/fa6";
import HelpMain from './Main.js';
import HelpMenuHeader from './HelpMenuHeader';
import HelpMenuSearch from './HelpMenuSearch';
import VersionInformation from './VersionInformation';
import WhatsNew from './WhatsNew';
import ContactSupport from './ContactSupport';
import Faqs from './Faqs';
import { HELP_SCREENS } from '../../../constants';
import styles from './HelpMenu.module.scss';

const HelpMenu = ({ show, onClose }) => {
    const [screen, setScreen] = useState('main');

    const handleScreenChange = (scr) => {
        setScreen(scr)
    }

    const handleBackToMain = () => {
        setScreen(HELP_SCREENS.main);
    }

    const renderHelpSection = (key) => {
        switch (key) {
            case HELP_SCREENS.screenHelp:
                return <div>Screen Help</div>;
            case HELP_SCREENS.faqs:
                return <Faqs />;
            case HELP_SCREENS.support:
                return <ContactSupport />;
            case HELP_SCREENS.whatsNew:
                return <WhatsNew />;
            case HELP_SCREENS.version:
                return <VersionInformation />;
            default:
                return null;
        }
    }

    return (
        <div
            className={`bg-white d-flex flex-column ${styles.helpPanel} ${!show && styles.close}`}>
            <HelpMenuHeader screen={screen} onClose={onClose} onBack={handleBackToMain} />
            <div
                className={`position-relative flex-grow-1 d-flex flex-column overflow-y-auto ${styles.body} ${styles.hasSearchBox}`}>
                {(screen === HELP_SCREENS.main || screen === HELP_SCREENS.faqs) && (
                    <HelpMenuSearch screen={screen} />
                )}
                <div className={`flex-grow-1 px-4 ${styles.helpScreen}`}>
                    {screen === 'main' ? (
                        <HelpMain onScreenChange={handleScreenChange} />
                    ) : (
                        <div className="py-2">
                            <div className="mx-n1 pb-1">
                                <Button variant="outline-secondary" className="btn-sm bg-transparent text-secondary py-0 px-1 border-0" onClick={handleBackToMain}>
                                    <FaArrowLeft size={18} />
                                </Button>
                            </div>
                            {renderHelpSection(screen)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default withAuth(HelpMenu);
