import React from 'react';
import { pharoVersionInfo } from '../../../utils/helper/info';

const VersionInformation = () => {
    return (
        <div className="py-3">
            <strong>Version:</strong> {pharoVersionInfo}
        </div>
    );
};

export default VersionInformation;
