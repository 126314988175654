import Update from './Update';

const updates = [
    {
        id: '1',
        title: 'Added ability to archive members',
        application: 'Advisory Group Management',
        summary: `<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>`,
        lastUpdate: '11/07/2024'
    },
    {
        id: '2',
        title: 'Modified BI charts for AG groups',
        application: 'Member Analytics',
        summary: `<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
          <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
        lastUpdate: '10/23/2024'
    },
    {
        id: '3',
        title: 'Modified admin rights for advisory groups',
        application: 'Admin Center',
        summary: `<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>`,
        lastUpdate: '10/23/2024'
    },
    {
        id: '4',
        title: 'Modified BI charts for AG groups',
        application: 'Member Analytics',
        summary: `<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
          <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>`,
        lastUpdate: '09/16/2024'
    },
];

const WhatsNew = () => {
    return (
        <div className="py-2">
            {updates.map((item) => (
                <Update key={item.id} item={item}>
                    {item.summary}
                </Update>
            ))}
        </div>
    );
};

export default WhatsNew;
