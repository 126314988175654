import { Button } from 'react-bootstrap';
import styles from './HelpMenu.module.scss';
import { companyContactInfo } from '../../../utils/helper/info';
import Icon from '../Icon';
import customStyles from './ContactSupport.module.scss';

const ContactSupport = () => {
    return (
        <div className="py-2">
            <h3 className={`fw-bold ${styles.contactTitle}`}>Create a Support Ticket</h3>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Hac elementum cubilia nisi ac fusce. Aliquam non
                suspendisse imperdiet sem taciti mollis etiam.</p>
            <div className="text-center">
                <Button>Create Support Ticket</Button>
            </div>

            <div className="text-secondary my-3 py-3">
                <hr className="opacity-100 my-0" />
            </div>

            <h3 className={`fw-bold ${styles.contactTitle}`}>Chat with a Support Representative</h3>
            <p>Lorem ipsum odor amet, consectetuer adipiscing elit. Hac elementum cubilia nisi ac fusce. Aliquam non
                suspendisse imperdiet sem taciti mollis etiam.</p>
            <div className="text-center mb-3 position-relative">
                <div className="d-inline-block position-relative">
                    <Button className='pe-3 ps-5 position-relative'>
                        Live Chat
                    </Button>
                    <Icon
                        icon="live-chat"
                        size={50}
                        className={`${customStyles.liveChatIcon} position-absolute top-50 start-0 translate-middle-y`}
                    />
                </div>
            </div>
            <p>
                {companyContactInfo.chat.label}<br />
                <span className="ms-2">{companyContactInfo.chat.hours}</span>
            </p>

            <div className="text-secondary my-3 py-3">
                <hr className="opacity-100 my-0" />
            </div>

            <h3 className={`fw-bold ${styles.contactTitle}`}>Call the Support Center</h3>
            <p className="text-center">
                <a href="tel:8001234567" className="fs-2 fw-bold text-secondary text-decoration-none">{companyContactInfo.supportCenter.phoneNumber}</a>
            </p>
            <p>
                {companyContactInfo.supportCenter.label}<br />
                <span className="ms-2">{companyContactInfo.supportCenter.hours}</span>
            </p>
        </div>
    );
};

export default ContactSupport;
