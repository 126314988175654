import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';
import {
    AG_RECRUITMENT_APPLICATION_STATUS_VALUE,
    ALERT_TYPE,
    ALIGN,
    EAC_PHONE_TYPE, MAX_VALUE,
    POSITIONS,
    RECORD_STATUS_ABBR,
    US_STATE,
    VALIDATIONS,
    YES_NO
} from "../../../constants";
import SharedForm from '../../../components/ui/Form';
import CommentList from '../../../components/ui/CommentList';
import { AlertContext } from '../../../context/AlertContext';
import {
    getMemberComments,
    insertMemberComment,
    processRecruitmentApplication,
    resetMemberComments,
} from '../../../ducks/advisoryGroup';
import messages from '../../../utils/helper/messages';
import { isObjectEmpty, optionValues } from "../../../utils/utils";
import styles from './ProcessApplicationModal.module.scss';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import api from '../../../services/api/cohortService';
import ConfirmModal from "../../../components/ui/ConfirmModal";
import Loader from "../../../components/ui/Loader";

const ProcessApplicationModal = ({ show, applicationData, statusData, onUpdate, onCancel }) => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const currentUser = useSelector((state) => state.user.currentUser);
    const memberComments = useSelector((state) => state.advisoryGroup.memberCommentList);

    const [isLoading, setIsLoading] = useState(false);
    const [isCommentLoading, setIsCommentLoading] = useState(false);
    const [applicationDetails, setApplicationDetails] = useState(null);
    const [comments, setComments] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);

    const applicationArr = [
        {
            key: 'participateReason',
            labelName: 'Reason for Participation',
            type: 'textarea',
            required: false,
            valid: VALIDATIONS.String.noSpecialChars,
        },
        {
            key: 'firstName',
            labelName: 'First Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'address',
            labelName: 'Address',
            type: 'text',
            maxLength: 35,
        },
        {
            key: 'city',
            labelName: 'City',
            type: 'text',
            maxLength: 50,
        },
        {
            key: 'state',
            labelName: 'State',
            type: 'options',
            optionValues: optionValues(US_STATE),
            maxLength: 2
        },
        {
            key: 'postalCode',
            labelName: 'Zip',
            type: 'number',
            minValue: 10000,
            maxValue: 999999999
        },
        {
            key: 'email',
            labelName: 'Email',
            type: 'email',
            required: false,
        },
        {
            key: 'phone',
            labelName: 'Phone',
            type: 'tel',
            maxLength: 10,
        },
        {
            key: 'phoneType',
            labelName: 'Phone Type',
            type: 'options',
            optionValues: optionValues(EAC_PHONE_TYPE, true),
            required: false,
        },
        {
            key: 'deviceWithInternetAccess',
            labelName: 'Device with Internet Access',
            type: 'text',
            required: false,
            maxLength: 16,
        },
        {
            key: 'primaryLanguage',
            labelName: 'Primary Language',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'needTranslator',
            labelName: 'Need Translator',
            placeholderName: 'Need Translator',
            type: 'options',
            optionValues: optionValues(YES_NO, true),
            required: false,
        },
        {
            key: 'needTransportation',
            labelName: 'Need Transportation',
            type: 'options',
            optionValues: optionValues(YES_NO, true),
            required: false,
        },
        {
            key: 'specificAccommodation',
            labelName: 'Specific Accommodation',
            type: 'options',
            optionValues: optionValues(YES_NO, true),
            required: false,
        },
        {
            key: 'specificAccommodationNeeds',
            labelName: 'Specific Accommodation Needs',
            type: 'text',
            disabled: true,
            conditional: true,
            dependsOn: 'specificAccommodation',
            dependsValue: ['Y'],
            required: false,
            maxLength: 50,
        },
        {
            key: 'dietaryRestrictionsAccommodation',
            labelName: 'Dietary Restrictions',
            placeholderName: 'Dietary Restrictions',
            type: 'options',
            optionValues: optionValues(YES_NO, true),
            required: false,
        },
        {
            key: 'dietaryRestrictionNeeds',
            labelName: 'Dietary Restrictions Needs',
            type: 'text',
            disabled: true,
            conditional: true,
            dependsOn: 'dietaryRestrictionsAccommodation',
            dependsValue: ['Y'],
            required: false,
            maxLength: 50,
        },
        {
            key: 'emergencyFirstName',
            labelName: 'Emergency Contact First Name',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'emergencyLastName',
            labelName: 'Emergency Contact Last Name',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'relationshipEmergency',
            labelName: 'Emergency Contact Relationship',
            type: 'text',
            required: false,
            maxLength: 35,
        },
        {
            key: 'phoneEmergency',
            labelName: 'Emergency Contact Phone',
            type: 'tel',
            required: false,
            maxLength: 10,
        },
        {
            key: 'phoneTypeEmergency',
            labelName: 'Emergency Contact Phone Type',
            type: 'options',
            optionValues: optionValues(EAC_PHONE_TYPE, true),
            required: false,
        },
    ];

    const statusArr = [
        {
            key: 'recordStatus',
            labelName: 'Status',
            placeholderName: 'Status',
            type: 'options',
            optionValues: optionValues(AG_RECRUITMENT_APPLICATION_STATUS_VALUE),
            required: false,
            cols: 6,
        },
        {
            key: 'memberId',
            labelName: 'Cohort Member ID',
            placeholderName: 'Cohort Member ID',
            type: 'text',
            required: false,
            cols: 6,
            maxLength: 10,
            maxValue: MAX_VALUE.INT,
            valid: VALIDATIONS.Number.isId
        },
    ];

    const handleApplicationDetailsChange = (update) => {
        setApplicationDetails((details) => ({ ...details, ...update }));
    }

    const handleInputChange = (name, value) => {
        handleApplicationDetailsChange({ [name]: value });
    }

    const resetModalData = () => {
        setComments(null);
        setApplicationDetails(null);
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.updateMemberRecord, ALERT_TYPE.SUCCESS);
        resetModalData();
        onUpdate();
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const isValidApplicationInfo = () => {
        return !isObjectEmpty(applicationDetails);
    }

    const handleCommentCancel = () => {
        let applicationDetailsCopy = {...applicationDetails};
        delete applicationDetailsCopy.comment;
        setApplicationDetails(applicationDetailsCopy);
    }

    const handleCommentSave = () => {
        if (!applicationDetails.comment) {
            return
        }

        setIsCommentLoading(true);
        const commentData = {
            agId: applicationData.aGId,
            agMemberId: applicationData.id,
            companyId: applicationData.companyId,
            comment: applicationDetails.comment,
            userId: currentUser?.id,
            status: RECORD_STATUS_ABBR.Operational
        }

        dispatch(insertMemberComment(commentData, () => {
            setIsCommentLoading(false);
            showAlert(messages.success.addComment, ALERT_TYPE.SUCCESS);
            dispatch(getMemberComments(applicationData.id));
            handleCommentCancel();
        }, (error) => {
            setIsCommentLoading(false);
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }));
    }

    const validateCohortMemberId = async (id) => {
        const validateObject = {
            id: applicationData.cohortId,
            memberId: id,
        }

        let result = await api.validateCohortMemberId(validateObject).then((response) => {
            const { data } = response;
            return data;
        }).catch((error) => {
            setIsLoading(false);
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            return false
        })

        return result;
    }

    const handleSubmit = async () => {
        if (!applicationDetails) {
            return
        }
        setIsLoading(true);
        const submitData = {
            agMemberId: applicationData.id,
            memberId: applicationDetails.memberId ?? applicationData.memberId,
            applicationId: applicationDetails.applicationId ?? applicationData.applicationId,
            applicationStatus: applicationDetails.recordStatus ?? applicationData.recordStatus,
            memberStatus: applicationDetails.memberStatus ?? applicationData.memberStatus,
            lastUser: currentUser?.id,
        }

        if (applicationDetails.recordStatus || applicationDetails.memberId) {
            if (applicationDetails.memberId) {
                // check if member id matches cohort member id and member status is not - No Contact, Excluded or Removed
                let isCohortMemberValid = await validateCohortMemberId(applicationDetails.memberId);
                if (!isCohortMemberValid) {
                    // reset state values on cohort member id validation error
                    let applicationDetailsCopy = {...applicationDetails};
                    delete applicationDetailsCopy.memberId;
                    if (applicationDetailsCopy.recordStatus) {
                        delete applicationDetailsCopy.recordStatus;
                    }
                    setApplicationDetails(applicationDetailsCopy);

                    setIsLoading(false);
                    showAlert(messages.error.cohortMemberInvalid, ALERT_TYPE.ERROR);
                    return
                }
            }
            dispatch(processRecruitmentApplication(submitData, handleSuccess, handleError));
        }
    }

    const checkApplicationIsUpdated = () => {
        return (
            statusData.memberId !== Number(applicationDetails.memberId) ||
            statusData.recordStatus !== applicationDetails.recordStatus
        )
    }

    const handleCancel = () => {
        const isApplicationDataUpdated = isObjectEmpty(applicationDetails) ? false : checkApplicationIsUpdated();
        if (isApplicationDataUpdated) {
            setConfirmModal(true);
        } else {
            resetModalData();
            onCancel();
            dispatch(resetMemberComments());
        }
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
    };

    const handleConfirm = () => {
        handleConfirmClose();
        showAlert(messages.success.noUpdatesProcessApplication, ALERT_TYPE.ERROR);
        resetModalData();
        onCancel();
        dispatch(resetMemberComments());
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getMemberComments(applicationData.id));
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        if (memberComments) {
            let commentList = [];
            for (let comment of memberComments) {
                commentList.push({
                    id: comment.id,
                    firstName: comment.firstName,
                    lastName: comment.lastName,
                    recordCreated: comment.memberCommentCreatedDate,
                    comment: comment.memberComment
                });
            }
            setComments(commentList);
        }
    }, [memberComments]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            dialogClassName={styles.modalContainer}>
            <Modal.Header closeButton>
                <Modal.Title>Process Application</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={`px-xl-3 pb-xl-2`}>
                    <Row>
                        <Col md={6} className={styles.formContainer}>
                            <h3 className={styles.title}>Application</h3>
                            <SharedForm
                                cols={12}
                                array={applicationArr}
                                modelObj={applicationData}
                                actionBarPosition={POSITIONS.BOTTOM}
                                actionBarAlign={ALIGN.RIGHT}
                                actionBarStyle={`d-none ${styles.modalAction}`}
                                viewOnly={true}
                                formStyle={`modal-with-scroll overflow-x-hidden overflow-y-auto bg-white px-3 py-3 mx-0 border border-1 rounded ${styles.applicationContainer}`}
                            />
                        </Col>
                        <Col md={6} className={`d-flex flex-column mt-3 mt-md-0 ${styles.rightPane}`}>
                            <div className="flex-shrink-0">
                                <SharedForm
                                    cols={12}
                                    array={statusArr}
                                    modelObj={statusData}
                                    loading={isLoading}
                                    onInputChanged={handleInputChange}
                                    onSubmit={handleSubmit}
                                    onCancel={handleCancel}
                                    disabled={!isValidApplicationInfo()}
                                    actionBarPosition={POSITIONS.TOP}
                                    actionBarAlign={ALIGN.RIGHT}
                                    submitButtonText='Save'
                                    cancelButtonText='Close'
                                />
                            </div>
                            <div className="position-relative">
                                {isCommentLoading &&
                                    <Loader />
                                }
                                <CommentList
                                    comments={comments}
                                    title={
                                        <h3
                                            className={`mb-0 lh-sm ${styles.title}`}>
                                            Comments
                                        </h3>
                                    }
                                    maxLength={255}
                                    customListStyle={`flex-grow-1 ${styles.commentsContainer}`}
                                    onCommentChange={handleInputChange}
                                    onCommentSave={handleCommentSave}
                                    onCommentCancel={handleCommentCancel}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <ConfirmModal
                    show={confirmModal}
                    message={messages.confirm.discardProgramEdit}
                    onConfirm={handleConfirm}
                    onCancel={handleConfirmClose}
                    onHideCallback={handleConfirmClose}
                />
            </Modal.Body>
        </Modal>
    );
}

export default ProcessApplicationModal;
