import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import CommentItem from "./CommentItem";
import { toDateTimeStamp } from "../../utils/utils";
import Loader from "./Loader";
import { getCommentsByMeetingId, resetMeetingMemberComments } from "../../ducks/advisoryGroup";

const CommentPopOver = ({ data, ...props }) => {
    const dispatch = useDispatch();

    const [showPopover, setShowPopover] = useState(false);

    const memberMeetingComments = useSelector((state) => state.advisoryGroup?.commentList);

    useEffect(() => {
        const handleClickOutside = () => setShowPopover(false);
        document.getElementById('attendance-details-modal').addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleComment = () => {
        if (!showPopover) {
            // Fetch comments when the popover is shown
            dispatch(getCommentsByMeetingId(data));
        } else {
            // reset comments when the popover is hidden
            dispatch(resetMeetingMemberComments());
        }
        setShowPopover(!showPopover);
    };

    const popover = (
        <Popover>
            <Popover.Body>
                {memberMeetingComments === null ? <Loader size={1} /> : memberMeetingComments?.length > 0 ?
                    memberMeetingComments.map((comment) => (
                        <CommentItem
                            key={comment.id}
                            id={comment.id}
                            date={toDateTimeStamp(comment.recordCreated)}
                            userName={`${comment.firstName} ${comment.lastName}`}>
                            {comment.comments}
                        </CommentItem>
                    )) : 'No comments'}
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            trigger="click"
            show={showPopover}
            onToggle={handleComment}
            overlay={popover}>
            <Button {...props}>{props.children}</Button>
        </OverlayTrigger>
    );
};

export default CommentPopOver;
